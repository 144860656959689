.partnerDetails{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .partnerInfo{
        width: 100%;
        height: 100%;
        max-height: 500px;
        display: grid;
        place-content: center;
        grid-template-columns: repeat(2, 1fr);
        .partnerPreview{
            width: 100%;
            height: 100%;
            max-height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: 95%;
                max-width: 700px;
                height: 100%;
                max-height: 500px;
                object-fit: cover;
            }
        }
        .partnerDetail{
            background: #eff2ff;
            height: 100%;
            width: 100%;
            padding: 30px;
            .itemTitle{
                color: #242a56;
                font-size: 3rem;
                text-align: center;
                margin-bottom: 30px;
            }
            .itemPara{
                font-size: 1.3rem;
                text-align: justify;
                color: #393A56;
                margin-bottom: 30px;
            }
            .priceRange{
                color: #242a56;
                .priceFrom{
                    margin-left: 20px;
                }
            }
        }
    }
    @media screen and (max-width:768px) {
        margin-top: 30px;
        .partnerInfo{
            grid-template-columns: repeat(1, 1fr);
            .partnerPreview{
                margin-bottom: 20px;
            }
            .partnerDetail{
                max-width: 95%;
                margin: 0 auto;
            }
        }
    }
    @media screen and (max-width:539px) {
        .partnerInfo{
            .partnerDetail{
                padding: 20px;
                .itemTitle{
                    font-size: 2rem;
                    margin-bottom: 20px;
                }
                .itemPara{
                    font-size: 1rem;
                    margin-bottom: 20px;
                }
                .priceRange{
                    .priceFrom{
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    @media screen and (max-width:400px) {
        .serviceInfo{

            .serviceDetail{
                .itemTitle{
                    font-size: 1.8rem;
                }
                .itemPara{
                    font-size: 0.8rem;
                }
            }
        }
    }

    // .partnerComments{
    //     .comment{}
    //     .commentForm{}
    // }
}