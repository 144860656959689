.promoteItem{
    width: 95%;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    cursor: pointer;
    // &:hover{
    //     transform: scale(1.1);
    //     box-shadow:6px 10px 20px 10px rgba(0,0,0,.6);
    // }
    box-shadow:3px 3px 9px 1px rgba(0,0,0,.6);
    .itemPic{
        width: 100%;
        height: 100%;
        max-height: 380px;
        overflow: hidden;
        border-radius: 8px 8px 0 0;
        img{
            width: 100%;
            height: auto;
            max-height: 100%;
            object-fit: cover;
        }
    }
    .itemDetails{
        width: 100%;
        height: 100%;
        padding: 15px 30px 30px;
        color: white;
        background: #6878d6ee;
        border-radius: 0 0 8px 8px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        .title{
            margin: 10px 0 5px;
            font-size: 2rem;
        }
        .desc{
            margin: 10px 0 20px;
            text-align: justify;
            font-size: 1rem;
        }
        .priceRange{
            font-size: 1rem;
            margin: 5px 0 10px;
        }
        .itemBtn{
            width: 80%;
            height: 35px;
            border: #C4A06C solid 1px;
            background: #C4A06C;
            color: white;
            font-size: 1rem;
            border-radius: 5px;
            box-shadow: 2px 2px 6px rgba(0,0,0,.3);
            a{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                color: white;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .itemDetails{
            padding: 5px 15px;
            .title{
                font-size: 1.5rem;
            }
            .desc{
                font-size: 0.9rem;
            }
            .priceRange{
                font-size: 0.9rem;
            }
        }
    }
    @media screen and (max-width: 610px) {
        .itemDetails{
            padding: 5px 15px;
            .title{
                font-size: 1rem;
            }
            .desc{
                font-size: 0.7rem;
            }
            .priceRange{
                font-size: 0.7rem;
            }
        }
    }
    @media screen and (max-width: 488px) {
        .itemDetails{
            padding: 5px 10px;
            .title{
                font-size: 0.9rem;
            }
            .desc{
                font-size: 0.5rem;
            }
            .priceRange{
                font-size: 0.5rem;
            }
            .itemBtn{
                font-size: 0.9rem;
            }
        }
    }
    @media screen and (max-width: 388px) {
        .itemDetails{
            padding: 5px 10px;
            .title{
                font-size: 0.8rem;
            }
            .desc{
                font-size: 0.5rem;
            }
            .itemBtn{
                font-size: 0.8rem;
            }
        }
    }
    @media screen and (max-width: 340px) {
        .itemDetails{
            padding: 5px 10px;
            .title{
                font-size: 0.8rem;
            }
            .desc{
                font-size: 0.5rem;
            }
            .itemBtn{
                font-size: 0.7rem;
            }
        }
    }
}