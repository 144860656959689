@import './_mixin.scss';

.reviewsitem{
    width: 100%;
    @include flexBox(column, center, center);
    background: white;
    margin: 10px;
    padding: 20px 10px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    .comments{
        width: 100%;
        @include flexBox(column, center, center);
        .quote{
            width: 100%;
            &.quote-left{
                transform: translateX(-25%);
            }
            &.quote-right{
                transform: translateX(25%) rotateY(180deg);
            }
        }
        .comment{
            width: 80%;
            margin: 10px auto;
            text-align: center;
            font-size: 12px;
        }
    }
    .authorNDate{
        width: 60%;
        margin: 10px auto;
        @include flexBox(column, center, center);
        .author{
            width: 100%;
            text-align: center;
            font-weight: bold;
        }
        .date{
            width: 100%;
            text-align: center;
        }
    }
}