@import './_mixin.scss';
.bg{
    min-height: 100vh;
    background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
    .memberCenter{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h1.title{
            font-size: 3rem;
            text-align: center;
            margin: 10px 0 30px;
            color: black;
            background: white;
            padding: 15px 30px;
            border-radius: 8px;
            box-shadow: 0 0 10px rgba(0,0,0,0.5);
            @media screen and (max-width:1570px) {
                font-size: 2.5rem;
            }
            @media screen and (max-width:425px) {
                font-size: 2rem;
            }
            @media screen and (max-width:330px) {
                font-size: 1.5rem;
            }
        }
        .memberSettings{
            width: 100%;
            @include flexBox(row, center, center);
            .settings{
                list-style: none;
                margin: 0;
                padding: 0 20px;
                width: 100%;
                margin-top: 30px;
                @include gridBox(2, 50px, 400px);
                .setting{
                    width: 100%;
                    height: 100%;
                    background: #6878d6;
                    border-radius: 8px;
                    color: white;
                    box-shadow: 0 0 10px rgba(0,0,0,0.5);
                    padding: 20px;
                    display: flex;
                    font-size: 2rem;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    transition: 0.3s;
                    &:hover{
                        background: #95a5ff;
                        transform: scale(1.1);
                    }
                    @media screen and (max-width:654px) {
                        font-size: 1.5rem;
                    }
                    @media screen and (max-width:522px) {
                        font-size: 1rem;
                        white-space: nowrap;
                        padding: 15px;
                    }
                    @media screen and (max-width:336px) {
                        font-size: 0.9rem;
                    }
                    @media screen and (max-width:316px) {
                        font-size: 1.2rem;
                    }
                
                }
                
                @media screen and (max-width:910px) {
                    grid-template-columns: repeat(2, 1fr);
                    gap: 30px;
                }
                @media screen and (max-width:316px) {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 30px;
                }
            }
        }
    }
}