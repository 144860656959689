.mainNav{
    min-height: 90px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.5);
    z-index: 9999 !important;
    background: rgba(0,0,0,0.6);
    position: fixed;
    top: 0;
    bottom: unset;
    &.mobile{
        min-height: 70px;
        top: unset;
        bottom: 0;
        display: none;
        background: rgba(0,0,0,1);
        .navWrap{
            min-height: 70px;
            .menu{
                min-height: 70px;
                .menuLink{
                    min-height: 70px;
                    font-size: 2rem;
                }
            }
        }
    }
    .navWrap{
        z-index: 9999 !important;
        min-height: 90px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        max-width: 1200px;
        .logo{
            margin-top: 10px;
            width: 20%;
            max-width: 165.97px;
            filter: drop-shadow(0 0 2px black);
            .logoImg{
                width: 100%;
            }
        }
        .menu{
            width: 80%;
            max-width: 80%;
            padding: 0;
            margin: 0;
            height: 100%;
            min-height: 90px;
            list-style: none;
            display: flex;
            justify-content: space-between;
            align-items: center;

            position: relative;
            .userSetting{
                position: absolute;
                background: white;
                top: 80%;
                left: 100%;
                transform: translateX(-100%);
                width: 100%;
                max-width: 200px;
                height: auto;
                max-height: 500px;
                border-radius: 8px;
                overflow: hidden;
                display: none;
                box-shadow: 2px 2px 5px 0 rgba(0,0,0,.5);
                &.show{
                    display: block;
                }
                .settingItem{
                    width: 100%;
                    height: 50px;
                    display: flex;
                    justify-content: space-between;
                    padding: 0 20px;
                    align-items: center;
                    font-size: 1rem;
                    cursor: pointer;
                    transition: background 0.2s ease-in-out;
                    white-space: nowrap;
                    color: #1a1a1a;
                    text-decoration: none;
                    span{
                        width: 70%;
                    }
                    .settingLink{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 1rem;
                        cursor: pointer;
                        white-space: nowrap;
                        color: #1a1a1a;
                        text-decoration: none;
                        &:hover, &:focus{
                            color: #ffffff;
                            // background: rgba(0,0,0,0.8);
                        }
                    }
                    &:hover, &:focus{
                        color: #ffffff;
                        background: rgba(0,0,0,0.8);
                    }
                }
                @media screen and (max-width:1220px) {
                    left: calc(100% - 20px);
                }
            }
            .menuLink{
                font-size: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                min-height: 90px;
                max-width: calc(100% / 6);
                height: 100%;
                color: white;
                cursor: pointer;
                margin: 0 10px;
                .userIcon{
                    margin-right: 10px;
                    &:hover{
                        color:#c48f6c;
                    }
                }
                .logoutIcon{
                    margin-left: 10px;
                    &:hover{
                        color:#c48f6c;
                    }
                }
                .username{
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    display: grid;
                    place-content: center;
                    color: white;
                    text-shadow: 0px 0px 3px black;
                }
                .userAvatar{
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    display: grid;
                    place-content: center;
                    place-items: center;
                    overflow: hidden;
                    text-shadow: 0px 0px 3px black;
                    svg{
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }
                &:hover{
                    color:#C4A06C;
                }
                a, button{
                    text-decoration: none;
                    color: white;
                    white-space: nowrap;
                    &.registerBtn{
                        width: 90%;
                        height: 100%;
                        min-width: 120px;
                        min-height: 50px;
                        white-space: nowrap;
                        border-radius: 8px;
                        font-size: 0.9rem;
                        outline: none;
                        border: none;
                        cursor: pointer;
                        background: #C4A06C;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &.active{
                            color:white;
                        }
                        &.pending{
                            color:#333333;
                        }
                    }
                    &.active{
                        color:#C4A06C;
                    }
                    &.pending{
                        color:#333333;
                    }
                }
            }
            @media screen and (max-width:830px) {
                .menuLink{
                    font-size: 0.8rem;
                }
            }
        }
        @media screen and (max-width:768px) {
            .menu{
                width: 50%;
                justify-content: flex-end;
                .menuLink{
                    display: none;
                    max-width: unset;
                    &.mobile{
                        display: flex;
                        max-width: 120px;
                    }
                }
            }
            .logo{
                width: 50%;
                max-width: 130px;
            }
        }
    }
    @media screen and (max-width:768px) {
        min-height: 70px;
        .navWrap{
            min-height: 70px;
            .logo{
                margin-top: 0;
            }
            .menu{
                min-height: 70px;
                .menuLink{
                    min-height: 70px;
                }
            }
        }
        &.mobile{
            display: flex;
            .navWrap{
                .menu{
                    width: 100%;
                    max-width: unset;
                    justify-content: space-between;
                    .menuLink{
                        display: flex;
                        max-width: unset;
                    }
                }
            }
        }
    }
}

.profileModal{
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999;
    display: none;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    background: rgba(0,0,0,0.6);
    &.show{
        opacity: 1;
        z-index: 99999999;
        display: flex;
    }
    .myProfile{
        width: 95%;
        height: 95%;
        max-width: 350px;
        max-height: 350px;
        background: white;
        box-shadow: 0 0 6px rgba(0,0,0,0.6);
        display: flex;
        justify-content: flex-start;
        border-radius: 8px;
        overflow: hidden;
        align-items: center;
        flex-direction: column;
        .profileTitle{
            position: relative;
            .closeProfile{
                position: absolute;
                right: 10px;
                top: 5px;
                font-size: 1.5rem;
                cursor: pointer;
            }
            display: flex;
            margin-bottom: 20px;
            border-radius: 8px 8px 0 0;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 50px;
            width: 100%;
            text-align: center;
            background: #333333;
            color: white;
        }
        .profileItem{
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            height: 80px;
            font-size: 1.5rem;
            .profilePic{
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                width: 50px;
                height: 50px;
                font-size: 1.2rem;
            }
            .profileName{
                font-size: 2rem;
            }
        }
    }
}