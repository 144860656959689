@import './_mixin.scss';
.serviceDetails{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .serviceInfo{
        width: 100%;
        height: 100%;
        display: grid;
        place-content: center;
        grid-template-columns: repeat(1, 1fr);
        .serviceDetail{
            background: #eff2ff;
            height: 100%;
            width: 100%;
            padding: 30px;
            .itemTitle{
                color: #242a56;
                font-size: 3rem;
                text-align: center;
                margin-bottom: 30px;
            }
            .itemPara{
                font-size: 1.3rem;
                text-align: center;
                color: #393A56;
                margin-bottom: 30px;
            }
        }
        .servicesDetailItems{
            width: 95%;
            margin: 30px auto;
            @include gridBox(5, 20px);
            .servicedetail{
                width: 100%;
                max-width: 300px;
                height: 100px;
                @include flexBox(row, center, center);
                background: #94a8ff;
                font-size: 1rem;
                color: #ffffff;
                text-align: center;
                font-weight: 700;
                border-radius: 8px;
            }
            @media screen and (max-width: 1290px) {
                grid-template-columns: repeat(4, 1fr);
            }
            @media screen and (max-width: 1050px) {
                grid-template-columns: repeat(3, 1fr);
            }
            @media screen and (max-width: 760px) {
                grid-template-columns: repeat(2, 1fr);
            }
            @media screen and (max-width: 500px) {
                .servicedetail{
                    font-size: 0.9rem;
                    height: 70px;
                }
            }
            @media screen and (max-width: 421px) {
                .servicedetail{
                    font-size: 0.8rem;
                    height: 60px;
                }
            }
            @media screen and (max-width: 320px) {
                .servicedetail{
                    font-size: 0.7rem;
                    height: 50px;
                }
            }
        }
    }
    @media screen and (max-width: 1728px) {
        width: 95%;
        margin: 0 auto;
    }
    @media screen and (max-width: 768px) {
        .serviceInfo{
            .serviceDetail{
                padding: 20px;
            }
        }
    }
    @media screen and (max-width: 755px) {
        .serviceInfo{
            .serviceDetail{
                .itemTitle{
                    font-size: 2.5rem;
                }
                .itemPara{
                    font-size: 1.1rem;
                }
            }
        }
    }
    @media screen and (max-width: 655px) {
        .serviceInfo{
            .serviceDetail{
                .itemTitle{
                    font-size: 2rem;
                }
                .itemPara{
                    font-size: 1rem;
                    text-align: justify;
                }
            }
        }
    }
    @media screen and (max-width: 521px) {
        .serviceInfo{
            .serviceDetail{
                .itemTitle{
                    font-size: 1.5rem;
                }
                .itemPara{
                    font-size: 0.9rem;
                    text-align: justify;
                }
            }
        }
    }
    @media screen and (max-width: 405px) {
        .serviceInfo{
            .serviceDetail{
                .itemTitle{
                    font-size: 1.2rem;
                }
                .itemPara{
                    font-size: 0.8rem;
                    text-align: justify;
                }
            }
        }
    }
    @media screen and (max-width: 360px) {
        .serviceInfo{
            .serviceDetail{
                .itemTitle{
                    font-size: 1.1rem;
                }
                .itemPara{
                    font-size: 0.7rem;
                    text-align: justify;
                }
            }
        }
    }
    @media screen and (max-width: 320px) {
        .serviceInfo{
            .serviceDetail{
                .itemTitle{
                    font-size: 1rem;
                }
                .itemPara{
                    font-size: 0.6rem;
                    text-align: justify;
                }
            }
        }
    }
}