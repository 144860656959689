footer{
    background-color: #242a56;
    color: #fff;
    padding: 20px;
    text-align: center;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-height: 400px;
    margin-top: auto;
}