@mixin flexBox($direction: row, $justify: center, $align: center, $wrap: nowrap) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  flex-wrap: $wrap;
}

@mixin gridBox($columns: 1, $gap: 0, $size: 1fr, $alignment: center, $alignmentItems: center) {
  display: grid;
  grid-template-columns: repeat($columns, $size);
  gap: $gap;
  place-content: $alignment;
  @if $alignmentItems != 'center' {
    place-items: $alignmentItems;
  } @else {
    place-items: $alignment;
  }
}
