@import './_mixin.scss';
#mainPage{
    width: 100%;
    height: 100%;
    // padding: 110px 0 0;
    position: relative;
    max-height: 85vh;
    min-height: 850px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url('./bgImg/mainBg.webp') fixed center center/cover no-repeat;
    @media screen and (max-width:768px) {
        min-height: 350px;
        max-height: 70vh;
    }
}
.displayPercent{
    position: fixed;
    right: 20px;
    background: red;
    width: 100px;
    height: 100px;
    color: white;
    border-radius: 50%;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
}
.promotionsBanner{
    width: 100%;
    height: 60vh;
    margin: 160px auto 0;
    display: flex;
    max-height: 550px;
    min-height: 300px;
    justify-content: center;
    align-items: flex-start;
    @media screen and (max-width:768px) {
        width: calc(100% - 30px);
        height: 40vh;
        margin-top: 250px;
    }
}

.serachItems{
    width: 100%;
    margin-top: 20px;
    height: 100px;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    #searchForm{
        margin: 50px auto 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        max-width: 1000px;
        align-items: center;
        box-shadow: 3px 3px 6px 3px rgba(0,0,0,0.5);
        border-radius: 50px;
        position: relative;
        overflow: hidden;
        input[type=search]{
            background: rgba(255,255,255,0.9);
            backdrop-filter: blur(5px);
            height: 50px;
            width: 100%;
            font-size: 1.5rem;
            padding: 5px 90px 5px 10px;
            border: none;
            border-radius: 50px;
            text-align: center;
            &::placeholder{
                text-align: center;
                font-size: 1.5rem;
            }
            &:focus{
                border: none;
                outline: 0;
            }
            @media screen and (max-width: 422px){
                &::placeholder{
                    text-align: center;
                    font-size: 1rem;
                }
                font-size: 1rem;
            }
            @media screen and (max-width: 344px){
                &::placeholder{
                    text-align: center;
                    font-size: 0.8rem;
                }
                font-size: 0.8rem;
            }
        }
        button[type=submit]{
            border: none;
            position: absolute;
            right: -1px;
            background: #6878d6;
            border-top-right-radius: 51px;
            border-bottom-right-radius: 51px;
            @include flexBox(row, center, center);
            color: white;
            height: 50px;
            width: 80px;
            font-size: 1.5rem;
            cursor: pointer;
        }
    }
    @media screen and (max-width:1020px) {
        width: 95%;
    }
}