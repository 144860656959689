@import './_mixin.scss';

.aboutUs, .aboutservice{
    width: 100%;
    display: grid;
    grid-template-areas: "aboutContentTitle aboutContentTitle"
                         "aboutImg aboutContent";
    place-content: center;
    place-items: center;
    grid-gap: 20px;
    padding: 0 20px 20px;
    margin: 60px auto 30px;
    p.content{
        grid-area: aboutContent;
        padding: 0 15px;
        text-align: justify;
        font-size: 1.2rem;
        .contentTitle{
            text-align: center;
            display: block;
        }
    }
    .contentTitle{
        grid-area: aboutContentTitle;
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: right;
    }

    .aboutImg{
        grid-area: aboutImg;
    }
    @media screen and (max-width:980px) {
        margin-bottom: 30px;
        grid-template-areas: "aboutContentTitle aboutContentTitle" 
                             "aboutImg aboutImg"
                             "aboutContent aboutContent";
        p.content{
            margin: 30px auto;
            max-width: 800px;
            .contentTitle{
                text-align: center;
            }
        }
    }
    @media screen and (max-width:555px) {
        p.content{
            font-size: 1rem;
            padding: 0 10px;
            .contentTitle{
                font-size: 1.5rem;
            }
        }
    }
    @media screen and (max-width:454px) {
        p.content{
            margin: 30px auto;
            width: 100%;
            max-width: 500px;
            font-size: 0.8rem;
            .contentTitle{
                font-size: 1.2rem;
            }
        }
        .contentTitle{
            font-size: 1.5rem;
        }
    }
    @media screen and (max-width:384px) {
        p.content{
            font-size: 0.7rem;
        }
    }
    @media screen and (max-width:365px) {
        p.content{
            font-size: 0.6rem;
        }
        .contentTitle{
            font-size: 1rem;
        }
    }
    @media screen and (max-width:320px) {
        p.content{
            font-size: 0.5rem;
            .contentTitle{
                font-size: 1rem;
            }
        }

    }
}

.aboutservice{
    p.content{
        grid-area: aboutContent;
        .contentTitle{
            text-align: left;
        }
    }

    .aboutserviceImg{
        grid-area: aboutserviceImg;
    }

    &.reverse{
        grid-template-areas: "aboutserviceImg aboutContent";
        p.content{
            .contentTitle{
                text-align: right;
            }
        }
    }
    grid-template-areas: "aboutContent aboutserviceImg";

    @media screen and (max-width:1131px) {
        p.content{
            .contentTitle{
                font-size: 1.7rem;
            }
        }
    }
    @media screen and (max-width:980px) {
        margin-bottom: 30px;
        margin-top: 0;
        grid-template-areas: "aboutserviceImg aboutserviceImg"
                             "aboutContent aboutContent";
        &.reverse{
            grid-template-areas: "aboutserviceImg aboutserviceImg"
                                 "aboutContent aboutContent";
        }
        &.reverse{
            p.content{
                margin: 30px auto;
                max-width: 800px;
                .contentTitle{
                    text-align: center;
                }
            }
        }
        p.content{
            margin: 30px auto;
            max-width: 800px;
            .contentTitle{
                text-align: center;
            }
        }
    }
    @media screen and (max-width:600px) {
        p.content{
            .contentTitle{
                font-size: 1.6rem;
            }
        }
    }
    @media screen and (max-width:551px) {
        p.content{
            .contentTitle{
                font-size: 1.3rem;
            }
        }
    }
    @media screen and (max-width:455px) {
        p.content{
            .contentTitle{
                font-size: 1.1rem;
            }
        }
    }
    @media screen and (max-width:395px) {
        p.content{
            .contentTitle{
                font-size: 1rem;
            }
        }
    }
    @media screen and (max-width:365px) {
        p.content{
            .contentTitle{
                font-size: 0.8rem;
            }
        }
    }
}