.searchResults{
    width: 100%;
    max-width: 1600px;
    margin: 20px auto;
    display: grid;
    place-content: center;
    place-items: center;
    grid-template-columns: repeat(4, 1fr);
    @media screen and (max-width:1300px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width:900px) {
        grid-template-columns: repeat(2, 1fr);
    }
    &.NoResult{
        grid-template-columns: repeat(1, 1fr);
    }
}
.searchResultsTitle{
    font-size: 3rem;
    margin: 40px 0;
    text-align: center;
    @media screen and (max-width:900px) {
        font-size: 2rem;
    }
}