@import './_mixin.scss';
.swiper{
    width: 100%;
    height: 100%;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    
    .swiper-wrapper{
        width: 100%;
        height: 100%;
        position: relative;
        .swiper-slide{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
            img{
                width: 100%;
                height: 100%;
                z-index: 1;
            }
            .promotions{
                width: 100%;
                height: 100%;
                min-height: 500px;
                background: radial-gradient(circle, rgba(238,174,202,0.95   ) 0%, rgba(148,187,233,0.95    ) 100%);
                color: black;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 5px 10px;
                &.reverse{
                    flex-direction: row-reverse;
                }
                .promoTitle, .promoSubDetail{
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1rem;
                }
                .promoTitle{
                    font-size: 2rem;
                }
                .promoSubDetail{
                    flex-direction: column;
                    max-width: 450px;
                    margin: 0 auto;
                    height: 250px;
                    border-radius: 8px;
                    color: white;
                    background: rgba(0, 0, 0, 0.6);
                    .content{
                        width: 80%;
                        text-align: justify;
                        margin-bottom: 30px;
                    }
                    .promoBtn{
                        padding: 15px 30px;
                        text-decoration: none;
                        color: white;
                        border-radius: 8px;
                        background: #C4A06C;
                        &:focus{
                            color: white;
                        }
                    }
                }
                @media screen and (max-width:790px) {
                    .promoTitle{
                        font-size: 1rem;
                    }
                    .promoSubDetail{
                        font-size: 0.8rem;
                        max-width: 350px;
                        height: 200px;
                    }
                }
                @media screen and (max-width:480px) {
                    flex-direction: column;
                    justify-content: center;
                    &.reverse{
                        flex-direction: column;
                    }
                    // .promoTitle{
                    //     transform: scale(0.8);
                    // }
                    .promoSubDetail{
                        width: 80%;
                        min-width: 250px;
                        height: 180px;
                        transform: scale(0.8);
                    }
                }
                @media screen and (max-width:480px) {
                    .promoTitle{
                        transform: scale(0.8);
                    }
                    .promoSubDetail{
                        transform: scale(0.7);
                    }
                }
            }
        }
    }
    &.client{
        box-shadow: unset;
        border-radius: unset;
        .swiper-button-prev, .swiper-button-next{
            color: #6878d6;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            padding: 5px;
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
        }
        padding: 30px 10px;
        margin: 60px auto 0;
    }
    &.reviews{
        box-shadow: unset;
        border-radius: unset;
        .swiper-button-prev, .swiper-button-next{
            color: #6878d6;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            padding: 5px;
            @include flexBox(row, center, center);
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
            text-align: center;
            &::after{
                font-size: 1.2rem;
                text-align: center;
            }
        }
        .swiper-button-prev{
            &::after{
                padding-right: 3px;
            }
        }
        .swiper-button-next{
            &::after{
                padding-left: 3px;
            }
        }
        padding: 30px 10px;
        margin: 60px auto 0;
    }
}
.swiper-pagination-bullet {
    width: 20px;
    height: 3px;
    margin: 0 2px !important;
    padding: 0;
    border-radius: 0;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    opacity: 1;
    background: rgba(255, 255, 255, 0.2);
    transition: background 0.3s ease-in-out;
}
.swiper-pagination-bullet-active {
    background: rgba(255, 255, 255, 1);
}