.allServices{
    width: 100%;
    height: auto;
    display: grid;
    place-content: center;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 60px;
    @media screen and (max-width:1268px) {
        grid-template-columns: repeat(1, 1fr);
    }
    // @media screen and (max-width:1400px) {
    //     grid-template-columns: repeat(2, 1fr);
    // }
    // @media screen and (max-width:1000px) {
    //     grid-template-columns: repeat(2, 1fr);
    // }
}
.allServicesTitle{
    font-size: 3rem;
    text-align: center;
    margin: 10px 0 30px;
    color: white;
    @media screen and (max-width:1570px) {
        font-size: 2.5rem;
    }
    @media screen and (max-width:425px) {
        font-size: 2rem;
    }
    @media screen and (max-width:330px) {
        font-size: 1.5rem;
    }
}