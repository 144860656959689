body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    min-height: 100vh;

  user-select: none;
  // overflow-y: overlay;
  &.freeze{
    overflow-y: hidden;
  }
}
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
*::-webkit-scrollbar{
  position: fixed;
  width: 0px;
  background: transparent;
  left: 0;
  top: 0;
}
*::-webkit-scrollbar-thumb{
  background: #ccc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container{
  width: 100%;
  max-width: 1688px;
  margin: 0 auto;
  height: 100%;
  position: relative;
  z-index: 1 !important;
  &.banner{
    min-height: 900px;
    // padding: 80px 0 100px;
    @media screen and (max-width:768px) {
      padding-top: 30px;
    }
  }
  &.down90{
    padding-top: 110px;
  }
  &.horizontal{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &.spaceBetween{
      height: 100%;
      justify-content: space-between;
    }
  }
}
.container-md{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  position: relative;
  z-index: 1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  &.down90{
    padding-top: 110px;
  }
  &.horizontal{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &.spaceBetween{
      height: 100%;
      justify-content: space-between;
    }
  }
}

.btn{
  width: 100%;
  max-width: 220px;
  border-radius: 50px;
  height: 100%;
  max-height: 40px;
  background: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  border: 1px black solid;
  &.submit{
    max-height: 50px;
  }
}

.notFound{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 80vh;
  width: 100%;
  font-size: 2rem;
  color: black;
}