@import './_mixin.scss';
.serviceItem{
    width: 95%;
    max-width: 600px;
    margin: 10px auto;
    height: 250px;
    padding: 20px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    background: #6878d6;
    cursor: pointer;
    // &:hover{
    //     transform: scale(1.1);
    //     box-shadow:6px 10px 20px 10px rgba(0,0,0,.6);
    // }
    box-shadow:3px 3px 9px 1px rgba(0,0,0,.6);
    .itemPic{
        width: 100px;
        height: 50px;
        font-size: 3rem;
        color: #ffffff;
        border-radius: 8px 8px 0 0;
        @include flexBox(row, center, center)
    }
    .itemDetails{
        width: 100%;
        height: 200px;
        padding: 5px 30px;
        color: #ffffff;
        border-radius: 0 0 8px 8px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        .title{
            margin: 10px 0 5px;
            font-size: 1.3rem;
        }
        .desc{
            margin: 0 0 5px;
            text-align: justify;
            font-size: 1rem;
            color: #eeeeee;
            margin: 10px 0;
        }
        .itemBtn{
            width: 80%;
            height: 35px;
            border: #ffffff solid 1px;
            background: #ffffff;
            color: #6878d6;
            border-radius: 5px;
            box-shadow: 2px 2px 6px rgba(0,0,0,.3);
            a{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                color: #6878d6;
            }
        }
    }
    @media screen and (max-width:518px) {
        .itemDetails{
            padding: 5px 20px;
            .title{
                font-size: 1.1rem;
            }
            .desc{
                font-size: 0.8rem;
            }
            .itemBtn{
                width: 90%;
            }
        }
    }
    @media screen and (max-width:518px) {
        .itemDetails{
            padding: 5px 20px;
            .title{
                font-size: 0.9rem;
            }
            .desc{
                font-size: 0.7rem;
            }
            .itemBtn{
                width: 95%;
                font-size: 0.7rem;
            }
        }
    }
    @media screen and (max-width:418px) {
        .itemDetails{
            padding: 5px 10px;
            .title{
                font-size: 0.8rem;
            }
            .desc{
                font-size: 0.6rem;
            }
            .itemBtn{
                width: 95%;
                font-size: 0.6rem;
            }
        }
    }
    @media screen and (max-width:318px) {
        .itemDetails{
            .title{
                font-size: 0.7rem;
            }
            .desc{
                font-size: 0.5rem;
            }
            .itemBtn{
                width: 95%;
                font-size: 0.55rem;
            }
        }
    }
}