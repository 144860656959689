.allCases{
    width: 100%;
    height: auto;
    display: grid;
    place-content: center;
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 60px;
    margin-bottom: 60px;
    .caseTitle{
        font-size: 2rem;
        text-align: center;
        margin: 60px 0 10px;
        color: #6878d6;
        @media screen and (max-width:1570px) {
            font-size: 2.5rem;
        }
        @media screen and (max-width:425px) {
            font-size: 2rem;
        }
        @media screen and (max-width:330px) {
            font-size: 1.5rem;
        }
    }
    // @media screen and (max-width:1570px) {
    //     grid-template-columns: repeat(4, 1fr);
    // }
    // @media screen and (max-width:1400px) {
    //     grid-template-columns: repeat(3, 1fr);
    // }
    // @media screen and (max-width:1000px) {
    //     grid-template-columns: repeat(2, 1fr);
    // }
}