@import './_mixin.scss';

.allPartners{
    width: 100%;
    height: auto;
    @include flexBox( column, center, center );
    padding: 40px 0 20px;
    .benefitsItems{
        width: calc(100% - 40px);
        margin: 60px 0;
        @include gridBox(3, 20px);
        .item{
            width: 100%;
            margin: 5px 20px;
            @include flexBox( column, center, center );
            .itemIcon{
                width: 100%;
                height: auto;
                text-align: center;
                font-size: 3rem;
                svg{
                    width: 100px;
                }
            }
            .itemTitle{
                width: 100%;
                height: auto;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                color: #000;
                line-height: 24px;
                margin-top: 20px;
            }
            .itemPara{
                width: 100%;
                max-width: 280px;
                height: auto;
                text-align: justify;
                font-size: 0.9rem;
                font-weight: 400;
                color: #000;
                line-height: 24px;
                margin: 20px auto;
            }
        }
        @media screen and (max-width:940px) {
            .item{
                .itemIcon{
                    font-size: 2.5rem;
                }
                .itemTitle{
                    font-size: 1rem;
                }
                .itemPara{
                    max-width: 280px;
                    font-size: 0.8rem;
                    margin: 20px auto;
                }
            }
        }
        @media screen and (max-width:800px) {
            .item{
                .itemIcon{
                    font-size: 2.5rem;
                }
                .itemTitle{
                    font-size: 0.9rem;
                }
                .itemPara{
                    max-width: 260px;
                    font-size: 0.7rem;
                    margin: 20px auto;
                }
            }
        }
        @media screen and (max-width:712px) {
            @include gridBox(2, 15px);
            .item{
                .itemIcon{
                    font-size: 3rem;
                }
                .itemTitle{
                    font-size: 1rem;
                }
                .itemPara{
                    max-width: 280px;
                    font-size: 0.9rem;
                    margin: 20px auto;
                }
            }
        }
        @media screen and (max-width:618px) {
            .item{
                .itemIcon{
                    font-size: 2.5rem;
                }
                .itemTitle{
                    font-size: 0.9rem;
                }
                .itemPara{
                    max-width: 270px;
                    font-size: 0.8rem;
                    margin: 20px auto;
                }
            }
        }
        @media screen and (max-width:534px) {
            .item{
                .itemIcon{
                    font-size: 2.5rem;
                }
                .itemTitle{
                    font-size: 0.8rem;
                }
                .itemPara{
                    max-width: 260px;
                    font-size: 0.7rem;
                    margin: 20px auto;
                }
            }
        }
        @media screen and (max-width:474px) {
            .item{
                .itemIcon{
                    font-size: 2rem;
                }
                .itemTitle{
                    font-size: 0.7rem;
                }
                .itemPara{
                    max-width: 250px;
                    font-size: 0.6rem;
                    margin: 20px auto;
                }
            }
        }
        @media screen and (max-width:414px) {
            @include gridBox(1, 15px);

            .item{
                .itemIcon{
                    font-size: 2.5rem;
                }
                .itemTitle{
                    font-size: 0.8rem;
                }
                .itemPara{
                    max-width: 200px;
                    font-size: 0.7rem;
                    margin: 20px auto;
                }
            }
        }
    }
    @media screen and (max-width:500px){
        .partnerTitle{
            font-size: 1.2rem;
        }
    }
    @media screen and (max-width:375px){
        .partnerTitle{
            font-size: 1rem;
        }
    }
}

.reachOut{
    width: 100%;
    .reachOutContent{
        width: 100%;
        height: 500px;
        position: relative;
        z-index: 2;
        .overlay{
            z-index: 1;
            z-index: 1;
            position: absolute;
            inset: 0;
            width: 100%;
            background: rgba(0,0,0,0.5);
        }
        .reachOutImg{
            z-index: 0;
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
        .reachOutPhase{
            position: absolute;
            z-index: 2;
            color: white;
            width: 100%;
            padding: 0 40px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: justify;
            font-size: 2rem;
            @media screen and (max-width:960px) {
                font-size: 1.5rem;
            }
            @media screen and (max-width:735px) {
                font-size: 1.2rem;
            }
            @media screen and (max-width:485px) {
                font-size: 1rem;
                padding: 0 20px;
            }
            @media screen and (max-width:350px) {
                font-size: 0.8rem;
                padding: 0 10px;
            }
        }
    }
}
.formArea{
    width: 100%;
    height: 100%;
    min-height: 800px;
    @include flexBox(row, center, center);
    .formImg{
        width: 50%;
        height: 100%;
        object-fit: cover;
        // overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            border-radius: 4px;
            box-shadow: 0 0 6px rgba(0,0,0,0.8);
        }
    }
    .successSubmit{
        width: 100%;
        max-width: 350px;
        text-align: center;
        font-size: 1rem;
        color: green;
        font-weight: bold;
        padding: 10px 30px;
        background: white;
        border-radius: 6px;
        box-shadow: 0 0 6px rgba(0,0,0,0.3);
        margin: 20px auto;
    }
    #partnerForm{
        width: 50%;
        max-width: 700px;
        margin: 60px auto;
        padding: 10px 20px;
        display: grid;
        .formTitle{
            grid-area: formTitle;
            text-align: center;
            font-size: 2rem;
            margin: 10px auto;
        }
        .formInput{
            margin: 10px 0;
            input{
                width: 100%;
                height: 50px;
                padding: 10px 20px;
                border: 1px solid #ddd;
                border-radius: 50px;
                outline: 0;
                font-size: 1.2rem;
                text-align: center;
            }
            position: relative;
            .error{
                font-size: 12px;
                color: red;
                width: 100%;
                text-align: left;
                padding-left: 15px;
            }
            &.companyName{
                grid-area: CoName;
            }
            &.CompanyRegistrationNo{
                grid-area: CoReg;
            }
            &.personInCharge{
                grid-area: CoPic;
            }
            &.picContactNo{
                grid-area: CoPicConNo;
            }
            &.picEmail{
                grid-area: CoPicEmail;
                width: 100%;
                // margin: 0 auto;
            }
            &.submit{
                grid-area: CoSubmit;
                width: 100%;
                button{
                    height: 50px;
                    background: #242a56;
                    color: white;
                    &:hover{
                        background: #1a1a1a;
                    }
                }
                @include flexBox(row, center, center);
            }
        }
        grid-template-areas: "formTitle formTitle"
                             "CoName CoName"
                             "CoPic CoPic"
                             "CoReg CoPicConNo"
                             "CoPicEmail CoPicEmail"
                             "CoSubmit CoSubmit";
        grid-gap: 20px;
    }
    @media screen and (max-width:1450px) {
        position: relative;
        z-index: 1;
        .formImg{
            width: 100%;
            max-width: 870px;
            z-index: 0;
            position: absolute;
            overflow: hidden;
            @include flexBox(row, center, center);
            img{
                min-width: 850px;
                height: auto;
            }
        }
        #partnerForm{
            background: rgba(255,255,255, 0.6);
            backdrop-filter: blur(6px);
            border-radius: 6px;
            padding: 30px;
            width: 100%;
            z-index: 1;
        }
        .successSubmit{
            z-index: 1;
        }
    }
    @media screen and (max-width:851px) {
        #partnerForm{
            padding: 10px;
            width: 95%;
        }
    }
    @media screen and (max-width:660px) {
        #partnerForm{
            grid-template-areas: "formTitle formTitle"
                             "CoName CoName"
                             "CoPic CoPic"
                             "CoReg CoReg"
                             "CoPicConNo CoPicConNo"
                             "CoPicEmail CoPicEmail"
                             "CoSubmit CoSubmit";
            grid-gap: 10px;
        }
    }
    @media screen and (max-width:380px) {
        #partnerForm{
            padding: 10px;
            width: 95%;
            .formTitle{
                font-size: 1.5rem;
            }
            .formInput{
                input{
                    font-size: 1rem;
                }
            }
        }
    }
}