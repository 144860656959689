@import './_mixin.scss';

.caseDetails{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .caseInfo{
        width: 100%;
        height: 100%;
        display: grid;
        place-content: center;
        place-items: center;
        grid-template-columns: repeat(2, 1fr);
        .casePreview{
            width: 90%;
            height: 100%;
            max-height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: 95%;
                max-width: 700px;
                height: 100%;
                max-height: 500px;
                object-fit: cover;
            }
        }
        .caseDetail{
            background: #eff2ff;
            height: 100%;
            width: 100%;
            padding: 30px;
            .itemTitle{
                color: #242a56;
                font-size: 3rem;
                text-align: center;
                margin-bottom: 30px;
            }
            .itemPara{
                font-size: 1.3rem;
                text-align: justify;
                color: #393A56;
                width: 90%;
                max-width: 700px;
                margin: 0 auto 30px;
            }
            .rating{
                color: #242a56;
                font-size: 2rem;
                @include flexBox(column, center, center);
                .starRating{
                    @include flexBox(row, center, center);
                    margin: 15px auto;
                    font-size: 2rem;
                    color: rgb(255,210,80);
                }
                .priceFrom{
                    margin-left: 20px;
                }
            }
            .customerReviews{
                width: 100%;
                max-width: 800px;
                height: 200px;
                margin: 20px auto;
            }
        }
    }
    @media screen and (max-width:1700px){
        .caseInfo{
            width: 90%;
            margin: 0 auto;
            .caseDetail{
                padding: 20px;
                .itemTitle{
                    font-size: 2.2rem;
                    margin-bottom: 20px;
                }
                .itemPara{
                    font-size: 1.2rem;
                    margin-bottom: 20px;
                }
                .rating{
                    font-size: 1.3rem;
                    .starRating{
                        font-size: 1.3rem;
                    }
                }
                .customerReviews{
                    width: 95%;
                    max-width: 800px;
                    height: 200px;
                    margin: 20px auto;
                }
            }
        }
    }
    @media screen and (max-width:1536px) {
        margin-top: 80px;
        margin-bottom: 80px;
        .caseInfo{
            grid-template-columns: repeat(1, 1fr);
            .casePreview{
                margin-bottom: 30px;
                img{
                    width: 95%;
                    height: auto;
                    max-width: 600px;
                    max-height: 400px;
                    object-fit: cover;
                }
            }
            .caseDetail{
                max-width: 95%;
                margin: 0 auto;
            }
        }
    }
    @media screen and (max-width:837px) {
        width: 95%;
        margin: 80px auto;
        .caseInfo{
            .caseDetail{
                padding: 10px;
                .itemTitle{
                    font-size: 1.2rem;
                    margin-bottom: 15px;
                }
                .itemPara{
                    font-size: 0.9rem;
                }
                .rating{
                    font-size: 1rem;
                    .starRating{
                        margin: 15px auto;
                        font-size: 1rem;
                    }
                }
                .customerReviews{
                    width: 100%;
                    max-width: 800px;
                    height: 200px;
                    margin: 20px auto;
                }
            }
        }
    }
    @media screen and (max-width:800px) {
        transform: scale(0.8);
        min-height: 80vh;
        margin: -80px auto -50px;
    }
    @media screen and (max-width:644px) {
        transform: scale(0.7);
        margin: -120px auto -80px;
    }
    @media screen and (max-width:557px) {
        transform: scale(0.6);
        margin: -180px auto -120px;
    }
    @media screen and (max-width:480px) {
        min-height: 40vh;
        transform: scale(0.5);
        margin: -180px auto -120px;
    }
    @media screen and (max-width:480px) {
        .caseInfo{
            .caseDetail{
                transform: scale(0.9);
            }
        }
    }
    // .caseComments{
    //     .comment{}
    //     .commentForm{}
    // }
}