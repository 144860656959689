// .LoginNow{
//     position: absolute;
//     width: 100%;
//     justify-content: center;
//     align-items: center;
//     flex-direction: column;
//     position: fixed;
//     inset: 0;
//     width: 100%;
//     height: 100%;
//     z-index: 999999;
//     background: rgba($color: #000000, $alpha: 0.8);
//     transition: transform 0.3s ease-in-out;
//     display: flex;
// }
.loginForm{
    position: absolute;
    transform: translateX(2000px);
    transition: transform 0.3s ease-in-out;
    &.show{
        transform: translateX(0);
    }
    width: 100%;
    inset: 0;
    max-height: 600px;
    min-height: 570px;
    background: #ffffff;
    border-radius: 10px;
    padding: 20px;
    h5.title{
        font-size: 1.5rem;
        text-align: center;
        margin: 10px 0 5px;
        color: black;
        @media screen and (max-width:1570px) {
            font-size: 1rem;
        }
        // @media screen and (max-width:425px) {
        //     font-size: 1rem;
        // }
        // @media screen and (max-width:330px) {
        //     font-size: 1.5rem;
        // }
    }
    #loginLogin{
        color: black;
        .formItems{
            display: grid;
            width: 100%;
            padding: 10px 20px;
            grid-template-columns: repeat(1, 1fr);
            place-content: center;
            .formItem{
                width: 100%;
                display: flex;
                flex-direction: column;
                margin: 10px 0;
                label{
                    color: black;
                    font-size: 1rem;
                    margin-bottom: 5px;
                }
                input{
                    width: 100%;
                    height: 30px;
                    border: none;
                    border-radius: 5px;
                    padding: 0 10px;
                    background: white;
                    border: #000000 1px solid;
                    color: black;
                    font-size: 1rem;
                    &:focus{
                        outline: none;
                    }
                    &.loginBtn{
                        width: 100%;
                        height: 40px;
                        background: #C4A06C;
                        color: white;
                        font-size: 1.2rem;
                        margin: 10px 0 0;
                        border-radius: 5px;
                        border: #C4A06C 1px solid;
                        cursor: pointer;
                        transition: 0.3s ease-in-out;
                        &:hover{
                            background: #9b825f;
                        }
                        &.medium{
                            width: 80%;
                            margin: 0 auto;
                        }
                        &.small{
                            width: 45%;
                            margin: 0 auto;
                        }
                    }
                }
                .error{
                    color: red;
                    font-size: 0.8rem;
                    margin-top: 5px;
                }
            }
        }
    }
}