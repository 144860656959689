.pageHeader{
    width: 100%;
    height: 100%;
    min-height: 600px;
    background-color: #000000;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    font-weight: bold;
    position: relative;
    z-index: 1;
    margin-bottom: -90px;
    &::before{
        z-index: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        background-color: rgba(0, 0, 0, 0.6);
        content: ""
    }
    .pageHeaderImg{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        inset: 0;
        z-index: -1;
    }
    .pageTitle{
        z-index: 1;
    }
    @media screen and (max-width:1500px) {
        font-size: 2rem;
    }
    @media screen and (max-width:1500px) {
        min-height: 400px;
        font-size: 2rem;
    }
    @media screen and (max-width:768px) {
        min-height: 300px;
        .pageTitle{
            padding-top: 20px;
        }
        background-size: 350px;
        font-size: 1rem;
    }
    @media screen and (max-width:340px) {
        font-size: 0.8rem;
    }
}